export const ENGLISH_LANGUAGE_CODE = "en-US";

export const EVENT_ATTRIBUTE_INPUT_TEMPLATE = '<div class="mb-3"><label for="%NAME%" class="form-label">%NAME%</label><input type="%TYPE%" name="%NAME%" id="%NAME%" class="form-control %CLASS%" %VALUE%></div>';

export const EVENT_ATTRIBUTE_INPUT_CLASS = "attribute-input";

export const EVENT_ATTRIBUTE_UPDATE_INPUT_CLASS = "update-attribute-input";

export const EVENT_TYPE_CHECKBOX_TEMPLATE = '<div class="form-check"><input class="form-check-input filter-event-checkbox" type="checkbox" value="" id="%ID%-event-type-filter" data-event-type-id="%ID%">' +
    '<label class="form-check-label" for="%ID%-event-type-filter">%NAME%</label></div>';

export const EVENT_DATA_INPUT_TEMPLATE = '<div class="mb-3"><label for="event-name" class="form-label">Name</label><input type="text" class="form-control" id="event-name" value="%NAME_VALUE%" data-group-id="%GROUP_ID%"></div>' +
    '<div class="mb-3"><label for="update-editor" class="form-label">Description</label><div id="update-editor"></div></div>' +
    '<div class="mb-3"><label for="event-date" class="form-label">Year</label><input type="text" class="form-control" id="event-date" value="%DATE_VALUE%" disabled></div>';

export const EVENT_DATA_UPDATE_ACTIONS_TEMPLATE = '<button class="btn btn-primary me-2" type="submit" id="save-event-data" data-event-id="%EVENT_ID%" data-feature-id="%FEATURE_ID%">Save</button>' +
    '<button class="btn btn-primary me-2" type="submit" id="cancel-save-event-data" data-event-id="%EVENT_ID%" data-feature-id="%FEATURE_ID%">Cancel</button>';

export const VIEW_EVENT_DATA_TEMPLATE = '<div><div class="row"><h5>%NAME%</h5></div><div class="row">%VALUE%</div></div>';

export const VIEW_EVENT_DATA_ACTIONS_TEMPLATE = '<button class="btn btn-primary me-2" type="submit" id="edit-event" data-event-id="%EVENT_ID%" data-feature-id="%FEATURE_ID%">Edit</button>' +
    '<button class="btn btn-primary me-2" type="submit" id="edit-tile" data-bs-dismiss="offcanvas" data-event-id="%EVENT_ID%" data-feature-id="%FEATURE_ID%">Edit Tiles</button>' +
    '<button class="btn btn-primary me-2" type="submit" id="delete-event" data-bs-dismiss="offcanvas" data-event-id="%EVENT_ID%" data-feature-id="%FEATURE_ID%">Delete Event</button>';

// constants for events fired to enable or disable event editing mode.
export const ENABLE_EVENT_EDIT_MODE = "enable_event_edit";
export const DISABLE_EVENT_EDIT_MODE = "disable_event_edit";