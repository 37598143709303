import {searchEventAutocomplete} from "./model/Events";
import {searchEventsControl} from "./control/SearchEvents";

// function to render the search dropdown list
const handleRenderResultList = (list, data) => {
    const info = document.createElement("p");
    if (data.results.length > 0) {
        info.innerHTML = `Displaying <strong>${data.results.length}</strong> out of <strong>${data.matches.length}</strong> results`;
    } else {
        info.innerHTML = `Found <strong>${data.matches.length}</strong> matching results for <strong>"${data.query}"</strong>`;
    }
    list.prepend(info);
}

// function to render each individual item in result list
const handleRenderResultItem = (item, data) =>{
    // Modify Results Item Style
    item.style = "display: flex; justify-content: space-between;";
    // Modify Results Item Content
    item.innerHTML = `
        <span style="text-overflow: ellipsis; white-space: nowrap; overflow: hidden;">
        ${data.match}
        </span>`;
}

let autocomplete = new autoComplete({
    // options to handle querying the data
    data: {
        // function to send the search query and return a list of matched items
        src: async () => {
            let year = document.getElementById('year-slider').value;
            return await searchEventAutocomplete(searchEventsControl.getSearchTerm(), year);
        },
        // key in response to search against
        keys: ["name"]
    },
    // handle rendering the result list
    resultsList: {
        element: handleRenderResultList,
        noResults: true,
        tabSelect: true
    },
    // handle rendering each item in the list
    resultItem: {
        element: handleRenderResultItem,
        highlight: true
    },
    events: {
        input: {
            focus: () => {
                if (autocomplete.input.value.length) autocomplete.start();
            }
        }
    }
})

document.getElementById('autoComplete').addEventListener('open', (e) => {
    const info = document.createElement("p");
    const year = document.getElementById('year-slider').value;
    info.innerHTML = `Searching for events in year: <strong>${year}</strong>`;
    autocomplete.list.append(info);
})
