import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import {Circle, Fill, Stroke, Style} from "ol/style";
import {tileGrid} from "./Common";

export const eventLayer = new VectorLayer({
    source: new VectorSource({
        tileGrid: tileGrid
    }),
    style: (feature) => {
        let color = feature.getGeometry().getProperties()["color"];

        return new Style({
            image: new Circle({
                fill: new Fill({color}),
                stroke: new Stroke({color: "rgba(255,225,51,0.5)", width: 3}),
                radius: 7
            }),
            fill: new Fill({color}),
            stroke: new Stroke({color: "rgba(255,225,51,0.5)", width: 3}),
        })
    },
    opacity: 0.5
})