import {handleUpdateEvent} from "./UpdateEvent";
import {saveEventModalHandler} from "./SaveEvent.js"
import {map} from "../../Map";
import {eventDisplayLayer} from "../../layers/EventDisplay"
import SaveIcon from "/images/SaveIcon.svg";
import {DISABLE_EVENT_EDIT_MODE, ENABLE_EVENT_EDIT_MODE} from "../../constants";
import {EventEditControl} from "../EventEditControl";

class SaveEventsControl extends EventEditControl {
    constructor(opt_options) {
        const options = opt_options || {}

        const icon = document.createElement('img');
        icon.src = SaveIcon;

        let elementID = 'save-event-map-button';

        const button = document.createElement('button');
        button.appendChild(icon);
        button.id = elementID;
        button.className = "disabled"
        button.setAttribute('title', 'Save Event');
        button.setAttribute('type', 'button');
        button.disabled = true;

        const element = document.createElement('div');
        element.className = 'save-event-div ol-control ol-unselectable map-control';
        element.appendChild(button);

        super({
            element: element,
            target: options.target
        });

        this.elementID = elementID;

        button.addEventListener('click', this.handleSaveEvents.bind(this), false);
    }

    async handleSaveEvents(e) {
        // check if we need to save a new event or update and old event
        // turn off all modes
        this.removeInteractions()

        let eventId = document.getElementById('save-event-map-button').getAttribute('data-event-id')
        if(eventId){
            let featureId = document.getElementById('save-event-map-button').getAttribute('data-feature-id');
            let feature = eventDisplayLayer.getSource().getFeatureById(featureId)
            let eventData = feature.getProperties().geometry.values_;

            handleUpdateEvent(featureId, eventData);
        }else{
            await saveEventModalHandler();
        }

        // send out event to disable event editing buttons
        let disableEventEditButtons = new Event(DISABLE_EVENT_EDIT_MODE);
        document.dispatchEvent(disableEventEditButtons);
    }

    removeInteractions(){
        if (map.get('fill') === 'true'){
            let trigger_fill_off_event = new Event('trigger_fill_off');
            document.dispatchEvent(trigger_fill_off_event);
        } else if (map.get('paint') === 'true'){
            let trigger_paint_off_event = new Event('trigger_paint_off');
            document.dispatchEvent(trigger_paint_off_event);
        } else if(map.get('fill-region') === 'true'){
            let trigger_fill_region_off_event = new Event('trigger_fill_region_off');
            document.dispatchEvent(trigger_fill_region_off_event);
        } else if (map.get('erase') === 'true'){
            let trigger_eraser_off_event = new Event('trigger_eraser_off');
            document.dispatchEvent(trigger_eraser_off_event);
        }
    }
}

export const saveEventsControl = new SaveEventsControl();
export {SaveEventsControl};