import {getWidth} from "ol/extent";
import {get as getProjection} from "ol/proj";
import {TileGrid} from "ol/tilegrid";

export const tileSize = 256;

export const zoomRatios = [
    4.523561956,
    6.820178962,
    10.14210706,
    12.1411492,
    14.46301341
]

export const backgroundZoomRatios = [
    3,
    4,
    4.523561956,
    5,
    5.491853096
]

export const getResolutions = (tileSize, zoomArray) => {
    let resolutions = [];

    for(let ratio of zoomArray){
        let resolution = (getWidth(getProjection('EPSG:3857').getExtent()) / tileSize) / Math.pow(2, ratio);
        resolutions.push(resolution);
    }

    return resolutions;
}

export const tileGrid = new TileGrid({
    extent: getProjection('EPSG:3857').getExtent(),
    resolutions: getResolutions(tileSize, zoomRatios),
    tileSize: [tileSize, tileSize]
})

export const maxZoom = zoomRatios.length;
export const minZoom = 1;