import {clearEvents, destroyEditor, drawPolygonFromTiles, editor, resetTiles, setEditor, tiles} from "../../events"
import {getEventTypeAttributes} from "../../model/EventTypes";
import {createNewEditor} from "../../Editor";
import {drawLayer} from "../../layers/Draw";
import {addEvent} from "../../model/Events";
import {ENGLISH_LANGUAGE_CODE, EVENT_ATTRIBUTE_INPUT_CLASS, EVENT_ATTRIBUTE_INPUT_TEMPLATE} from "../../constants";
import {reloadLayer} from "../../layers/EventDisplay";

// create modal to save a new event
export const saveEventModalHandler = async () => {

    if(editor !== null){
        destroyEditor()
    }

    setEditor(await createNewEditor('#create-editor')
        .then(newEditor => {
            newEditor.setData('');
            return newEditor;
        })
        .catch((error) => {
            console.log(error);
        }))

    handleEventTypeAttributes();

    let createEventOffCanvasElement = document.getElementById('create-event-offcanvas');
    let createEventOffCanvas = new bootstrap.Offcanvas(createEventOffCanvasElement);
    createEventOffCanvas.toggle();

    addDurationEventListeners();

    renderColors();
}

function renderColors(){
    document.getElementById('color-list').innerHTML = "";
    document.getElementById('event-color').value = AVAILABLE_COLORS.Magenta;

    for(let [color, hex] of Object.entries(AVAILABLE_COLORS)){
        let option = document.createElement('option');
        option.value = hex;
        option.name = color;

        document.getElementById('color-list').appendChild(option);
    }
}

function addDurationEventListeners() {
    document.getElementById('from-slider').addEventListener('input', changeInputYear);
    document.getElementById('to-slider').addEventListener('input', changeInputYear);
    document.getElementById('from-slider-input').addEventListener('input', changeSliderYear);
    document.getElementById('to-slider-input').addEventListener('input', changeSliderYear);
}

function changeInputYear(e){
    document.getElementById(`${e.target.id}-input`).value = e.target.value;
}

function changeSliderYear(e) {
    let sliderID = e.target.id.replace('-input', '');
    document.getElementById(sliderID).value = e.target.value;
}


// get event type default attributes
const handleEventTypeAttributes = () => {
    let eventTypeID = document.getElementById('save-event-map-button').getAttribute('data-event-type-id');
    getEventTypeAttributes(eventTypeID)
        .then((result) => {
            renderCreateEventAttributesInput(result, 'create-event-attribute-table');
        })
}

// delete event functions
let resetCreateEventInputForm = () => {
    document.getElementById('event-name-input').value = '';
    resetTiles();
}


// render default attributes for this event type
const renderCreateEventAttributesInput = (eventTypeAttributes) => {
    let attributesHTML = [];

    eventTypeAttributes.forEach((attribute) => {
        let attributeTemplate = EVENT_ATTRIBUTE_INPUT_TEMPLATE.replaceAll('%NAME%', attribute.name);
        attributeTemplate = attributeTemplate.replaceAll('%TYPE%', attribute.type);
        attributeTemplate = attributeTemplate.replaceAll('%VALUE%', "");
        attributeTemplate = attributeTemplate.replaceAll('%CLASS%', EVENT_ATTRIBUTE_INPUT_CLASS);
        attributesHTML.push(attributeTemplate);
    })

    if(attributesHTML.length !== 0){
        let attributeDiv = document.getElementById('create-event-attribute-table');
        attributeDiv.className = 'mb-3'
        attributeDiv.innerHTML = attributesHTML.join('');
    }
}

// build attribute object to send to server
function handleEventAttributes(){
    let attributeElements = document.getElementsByClassName('attribute-input')
    let attributeObject = {};
    Array.from(attributeElements).forEach((attributeElement) => {
        let value = attributeElement.value === null ? "" : attributeElement.value;
        attributeObject[attributeElement.id] = {
            [ENGLISH_LANGUAGE_CODE]: value,
            type: attributeElement.type
        }
    })

    return attributeObject;
}

export const handleSaveEvent = (event) => {
    // get all tiles associated to this event
    // get the description and date and everything related to the event
    let eventName = document.getElementById('event-name-input').value;
    let eventDescription = editor.getData();
    let year = document.getElementById('year-slider').value;
    let eventTypeId = document.getElementById('save-event-map-button').getAttribute('data-event-type-id');

    // get duration slider values
    let durationStart = document.getElementById('from-slider').value;
    let durationEnd = document.getElementById('to-slider').value;

    let attributeObject = handleEventAttributes()

    let color_hex = document.getElementById('event-color').value;

    let geometryPayload = [];
    let featureArray = drawLayer.getSource().getFeatures();
    for(let i=0; i<featureArray.length; i++){
        geometryPayload.push(featureArray[i].getGeometry().getProperties());
    }

    let newEvent = {
        'type': eventTypeId,
        'name': eventName,
        'description': eventDescription,
        'tiles': Object.values(tiles),
        'geometries': geometryPayload,
        'attributes': attributeObject,
        'duration_start': durationStart,
        'duration_end': durationEnd,
        'color_hex': color_hex
    }

    addEvent(newEvent).then(()=>{
        reloadLayer();
    });

    resetCreateEventInputForm();

    clearEvents();
}