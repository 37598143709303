import {Control} from "ol/control";
import {DISABLE_EVENT_EDIT_MODE, ENABLE_EVENT_EDIT_MODE} from "../constants";

export class EventEditControl extends Control {
    elementID;

    constructor(opt_options){
        super(opt_options);

        document.addEventListener(ENABLE_EVENT_EDIT_MODE, this.handleEnableControl.bind(this), false);
        document.addEventListener(DISABLE_EVENT_EDIT_MODE, this.handleDisableControl.bind(this), false);
    }

    // general function to handle enabling a control element
    handleEnableControl(){
        let element = document.getElementById(this.elementID);
        element.className = "";
        element.disabled = false;
    }

    // general function to handle disabling a control element;
    handleDisableControl(){
        let element = document.getElementById(this.elementID);
        element.className = "disabled";
        element.disabled = true;
    }
}