export const getEventTypes = async () => {
    let request = await fetch(`/api/types`)

    if (!request.ok) {
        return [];
    }

    return await request.json();
}

export const getEventTypeAttributes = async (eventTypeID) => {
    let request = await fetch(`/api/types/${eventTypeID}/attributes`)

    if(!request.ok){
        return [];
    }

    return await request.json();
}