import {maxZoom, minZoom} from "../layers/Common";
import {EventEditControl} from "./EventEditControl";

class FillSizeSliderControl extends EventEditControl {
    fillSize;
    constructor(opt_options) {
        const options = opt_options || {};

        let elementID = 'fill-size-slider';

        const slider = document.createElement('input');
        slider.id = elementID;
        slider.setAttribute('type','range')
        slider.setAttribute('min', `${minZoom}`);
        slider.setAttribute('max', `${maxZoom}`);
        slider.setAttribute('value', `${maxZoom}`);
        slider.setAttribute('step', '1');
        slider.disabled = true;
        slider.className = "disabled";

        const element = document.createElement('div');
        element.className = 'fill-size-slider-div ol-control ol-unselectable map-control';
        element.appendChild(slider);

        super({
            element: element,
            target: options.target
        });

        this.elementID = elementID;
        this.fillSize = maxZoom;

        slider.addEventListener('change', this.handleFillSizeChange.bind(this), false);
    }

    handleFillSizeChange(e) {
        this.setFillSize(e.target.value);
    }

    setFillSize(fillSize){
        this.fillSize = fillSize;
    }

    getFillSize(){
        return this.fillSize;
    }
}

export const fillSizeSliderControl = new FillSizeSliderControl();
export {FillSizeSliderControl};