import {Control} from "ol/control";
import {getEventTypes} from "../model/EventTypes";
import PlusIcon from "/images/PlusIcon.svg";
import {ENABLE_EVENT_EDIT_MODE} from "../constants";

export class NewEvent extends Control {
    constructor(opt_options){
        const options = opt_options || {}

        const icon = document.createElement('img');
        icon.src = PlusIcon;

        const button = document.createElement('button');
        button.appendChild(icon);
        button.id = 'add-event';
        button.setAttribute('title', 'Add Event');
        button.setAttribute('type', 'button');

        const element = document.createElement('div');
        element.className = 'add-event-div ol-control ol-unselectable map-control';
        element.appendChild(button);

        super({
            element: element,
            target: options.target
        })

        button.addEventListener('click', this.handleNewEventButtonClick.bind(this), false);
        document.getElementById('create-event-button').addEventListener('click', this.handleCreateEventButtonClick.bind(this), false);
    }

    handleNewEventButtonClick(){
        // get all event types
        // open modal next to add button (or just somewhere) that has a select button of all event types
        // and a create button
        // when the create button is pressed, allow users to draw and save.
        getEventTypes()
            .then((result) => {
                this.renderCreateEventModal(result.types);
            })
    }

    renderCreateEventModal(eventTypes){
        // modal with select of all event types
        // create button
        // show this modal somewhere
        const selectElement = document.getElementById('event-type-select');
        selectElement.innerHTML = "";
        eventTypes.forEach((eventType) => {
            const option = document.createElement('option');
            option.value = eventType.id;
            option.textContent = eventType.name;

            selectElement.appendChild(option);
        })

        this.handleToggleModal()
    }

    handleCreateEventButtonClick(){
        const selectEventTypeElement = document.getElementById('event-type-select');
        const selectedEventTypeId = selectEventTypeElement.value;
        const selectedEventTypeName = selectEventTypeElement.options[selectEventTypeElement.selectedIndex].text;

        document.getElementById('save-event-map-button').setAttribute('data-event-type-id', selectedEventTypeId);
        document.getElementById('save-event').setAttribute('data-event-type-id', selectedEventTypeId);

        document.getElementById('event-type-input').value = selectedEventTypeName;

        // fire event to enable the tile editing mode
        let enableTileEditMode = new Event(ENABLE_EVENT_EDIT_MODE);
        document.dispatchEvent(enableTileEditMode);
    }

    handleToggleModal(){
        const addEventModal = new bootstrap.Modal(document.getElementById('create-event-modal'))
        addEventModal.toggle();
    }
}

export const newEventControl = new NewEvent();