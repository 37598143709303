import {Draw, Modify, Snap} from "ol/interaction";
import {map} from "../Map";
import {tileGrid} from "../layers/Common";
import {drawLayer} from "../layers/Draw";
import {LineString} from "ol/geom";
import {plotLine} from "../events";
import LassoIcon from "/images/LassoIcon.svg";
import {EventEditControl} from "./EventEditControl";

class PaintLineControl extends EventEditControl{
    draw;
    snap;
    modify;
    tiles;
    previousTile;
    constructor(opt_options) {
        const options = opt_options || {};

        const icon = document.createElement('img');
        icon.src = LassoIcon;
        icon.className = "lasso-icon";

        const elementID = 'paint-line';

        const button = document.createElement('button');
        button.appendChild(icon);
        button.id = elementID;
        button.className = "disabled"
        button.setAttribute('title', 'Draw Line');
        button.setAttribute('type', 'button');
        button.disabled = true;

        const element = document.createElement('div');
        element.className = 'paint-line-div ol-control ol-unselectable map-control';
        element.appendChild(button);

        super({
            element: element,
            target: options.target
        });

        this.elementID = elementID;
        this.tiles = {};

        button.addEventListener('click', this.handlePaintLine.bind(this), false)
        document.addEventListener('trigger_paint_off', this.handlePaintOff.bind(this), false);
        document.addEventListener('clear-events', this.resetDataHandler.bind(this), false);
    }

    handlePaintLine(){
        if(map.get('erase') === 'true'){
            let trigger_eraser_off_event = new Event('trigger_eraser_off');
            document.dispatchEvent(trigger_eraser_off_event);
        }else if(map.get('fill') === 'true'){
            let trigger_fill_off_event = new Event('trigger_fill_off');
            document.dispatchEvent(trigger_fill_off_event);
        }else if(map.get('fill-region') === 'true'){
            let trigger_fill_region_off_event = new Event('trigger_fill_region_off');
            document.dispatchEvent(trigger_fill_region_off_event);
        }

        if(map.get('paint') === 'false' || map.get('paint') === undefined){
            this.addInteraction();
            map.set('paint', 'true', true);
            console.log('Paint Selected');
        }
    }

    addInteraction(){
        this.draw = new Draw({
            source: drawLayer.getSource(),
            type: "LineString",
            geometryFunction: (coords, geom) => {
                if (!geom) {
                    geom = new LineString([]);
                }
                geom.setCoordinates(coords);
                geom.setProperties({'tiles': Object.values(this.tiles), 'fill': false})
                return geom.simplify();
            },
            freehand: true
        });

        this.snap = new Snap({
            source: drawLayer.getSource()
        });

        this.modify = new Modify({
            source: drawLayer.getSource()
        })

        map.addInteraction(this.modify);
        map.addInteraction(this.draw);
        map.addInteraction(this.snap);

        this.draw.addEventListener('drawstart', (e) => {
            this.resetPreviousTile();
            map.addEventListener('pointermove', this.drawHandler)
        });
        this.draw.addEventListener('drawend', (e) => {
            console.log('drawing finished')
            map.removeEventListener('pointermove', this.drawHandler)
        });
    }

    drawHandler = (e) => {
        this.handlePointerMove(e)
    }

    resetDataHandler () {
        this.tiles = {};
    }

    handlePointerMove(e) {
        this.previousTile = this.addGeometryXYZCoordinate(e.coordinate, this.previousTile)
    }

    addGeometryXYZCoordinate(coordinate, previousTile){

        let tileCoord =  tileGrid.getTileCoordForCoordAndZ(coordinate, 4);

        let tileCoords = [tileGrid.getTileCoordForCoordAndZ(coordinate, 4)];

        if(tileCoord !== previousTile && previousTile != null){
            // calculate min and max coordinates if not the same
            let xMin, xMax, yMin, yMax

            xMin = previousTile[1]
            xMax = tileCoord[1]
            yMin = previousTile[2]
            yMax = tileCoord[2]

            tileCoords = [...tileCoords, ...plotLine(xMin, yMin, xMax, yMax)]
        }

        for(let tileCoord of tileCoords){
            if(!(`${tileCoord[0]+1}-${tileCoord[1]}-${tileCoord[2]}` in this.tiles)){
                this.tiles[`${tileCoord[0]+1}-${tileCoord[1]}-${tileCoord[2]}`] = {z: tileCoord[0]+1,x: tileCoord[1],y: tileCoord[2]}
            }
        }

        return tileCoord;
    }

    removeInteractions(){
        map.removeInteraction(this.draw);
        map.removeInteraction(this.snap);
        map.removeInteraction(this.modify);

        this.draw = null;
        this.snap = null;
        this.modify = null;
    }

    handlePaintOff(e) {
        this.removeInteractions();
        map.set('paint', 'false', true);
        console.log('Paint unselected');
    }

    resetPreviousTile(){
        console.log('resetting previous tile')
        this.previousTile = null;
    }
}

export const paintLineControl = new PaintLineControl();
export {PaintLineControl}