import VectorSource from "ol/source/Vector";
import VectorLayer from "ol/layer/Vector";
import {tileGrid} from "./Common";

let drawLayerSource = new VectorSource({
    tileGrid: tileGrid
});
export const drawLayer = new VectorLayer({
    source: drawLayerSource,
    style: {
        'fill-color': 'rgb(255,204,51)',
        'stroke-color': '#ffcc33',
        'stroke-width': 5,
        'circle-radius': 10,
        'circle-fill-color': '#ffcc33',
    },
})