import {Control} from "ol/control";
import {eventDisplayLayer} from "../layers/EventDisplay";
import SearchIcon from "/images/SearchIcon.svg";
import {getEvent} from "../model/Events";
import {drawPolygonFromTiles} from "../events";
import {map} from "../Map";

export class SearchEvents extends Control{
    searchTerm;
    constructor(opt_options){
        const options = opt_options || {};

        const icon = document.createElement('img');
        icon.src = SearchIcon;

        const button = document.createElement('button');
        button.appendChild(icon);
        button.id = 'search-event';
        button.setAttribute('title', 'Search Events');
        button.setAttribute('type', 'button');

        const element = document.createElement('div');
        element.className = 'search-event-div ol-control ol-unselectable map-control';
        element.appendChild(button);

        super({
            element: element,
            target: options.target
        })

        this.searchTerm = "";
        button.addEventListener('click', this.handleClickSearchEventButton.bind(this), false);
        document.getElementById('autoComplete').addEventListener('keyup', this.handleSearchInputKeyDown.bind(this), false);

        document.getElementById('autoComplete').addEventListener('selection', this.handleSelectEvent.bind(this));
    }

    handleSelectEvent(e){
        // get selected event from database
        const event = getEvent(e.detail.selection.value.id)
            .then((event) => {
                // clear events from map
                eventDisplayLayer.getSource().clear();

                // render the event on the map
                drawPolygonFromTiles(event);

                // set extent of map to show just the event:
                let feature = eventDisplayLayer.getSource().getFeatureById(event.id);
                let extent = feature.getGeometry().getExtent();
                map.getView().fit(extent);
            })
            .catch((err) => {
                console.log(err);
            })

        // close search modal
        this.handleCloseModal();
    }

    handleClickSearchEventButton(){
        // open the search menu
        this.handleToggleModal();
    }

    handleInputSearchTerm(){
        let searchInputElement = document.getElementById('autoComplete')
        this.searchTerm = searchInputElement.value;
    }

    handleSearchInputKeyDown(e){
        this.handleInputSearchTerm();

        if(e.key === 'Enter'){
            this.handleCloseModal();
            this.refreshData();
        }
    }

    getSearchTerm(){
        return this.searchTerm;
    }

    refreshData(){
        eventDisplayLayer.getSource().clear();
        eventDisplayLayer.getSource().refresh();
    }

    handleToggleModal(){
        let searchModalElement = document.getElementById('search-event-modal');
        let searchModal = new bootstrap.Modal(searchModalElement)

        this.handleFocusSearchEventInput();

        searchModal.toggle();
    }

    // Focus on the input field after the modal is shown
    handleFocusSearchEventInput(){
        let searchModalElement = document.getElementById('search-event-modal');
        searchModalElement.addEventListener('shown.bs.modal', () => {
            let searchInput = document.getElementById('autoComplete');
            if (searchInput) {
                searchInput.focus();
            }
        }, { once: true });
    }

    handleCloseModal(){
        let searchModalElement = document.getElementById('search-event-modal');
        let searchModal = bootstrap.Modal.getInstance(searchModalElement)
        searchModal.hide();
    }
}

export const searchEventsControl = new SearchEvents();