import {map} from "../Map";
import {handleTileClickHandler, handleTileDrag} from "../events";
import PaintBrushIcon from "/images/PaintBrushIcon.svg";
import {EventEditControl} from "./EventEditControl";

class SelectTileControl extends EventEditControl {
    constructor(opt_options) {
        const options = opt_options || {}

        const icon = document.createElement('img');
        icon.src = PaintBrushIcon;

        const elementID = 'select-tile';

        const button = document.createElement('button');
        button.appendChild(icon);
        button.id = elementID;
        button.className = "disabled"
        button.setAttribute('title', 'Paint Event');
        button.setAttribute('type', 'button');
        button.disabled = true;

        const element = document.createElement('div');
        element.className = 'select-tile-div ol-control ol-unselectable map-control';
        element.appendChild(button);

        super({
            element: element,
            target: options.target
        });

        this.elementID = elementID;

        button.addEventListener('click', this.handlePaintEvents.bind(this), false);
        document.addEventListener('trigger_fill_off', this.handlePaintEventsOff.bind(this), false);
    }

    handlePaintEvents() {
        if (map.get('erase') === 'true'){
            let trigger_eraser_off_event = new Event('trigger_eraser_off');
            document.dispatchEvent(trigger_eraser_off_event);
        } else if (map.get('paint') === 'true'){
            let trigger_paint_off_event = new Event('trigger_paint_off');
            document.dispatchEvent(trigger_paint_off_event);
        } else if(map.get('fill-region') === 'true'){
            let trigger_fill_region_off_event = new Event('trigger_fill_region_off');
            document.dispatchEvent(trigger_fill_region_off_event);
        }

        if (map.get('fill') === 'false' || map.get('fill') === undefined) {
            map.addEventListener('click', handleTileClickHandler);
            map.addEventListener('pointerdrag', handleTileDrag)
            map.set('fill', 'true', true);
            console.log('Highlight selected');
        }
    }

    handlePaintEventsOff(e){
        map.removeEventListener('click', handleTileClickHandler);
        map.removeEventListener('pointerdrag', handleTileDrag);
        map.set('fill', 'false');
        console.log('Highlight unselected');
    }
}

export const selectTileControl = new SelectTileControl();
export {SelectTileControl};