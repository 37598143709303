import {Control} from "ol/control";
import {eventDisplayLayer} from "../layers/EventDisplay";
import {getEventTypes} from "../model/EventTypes";
import SettingsIcon from "/images/SettingsIcon.svg";
import {EVENT_TYPE_CHECKBOX_TEMPLATE} from "../constants";

export class FilterEvents extends Control{
    eventTypes;
    constructor(opt_options){
        const options = opt_options || {}

        const icon = document.createElement('img');
        icon.src = SettingsIcon;

        const button = document.createElement('button');
        button.appendChild(icon);
        button.id = 'filter-event';
        button.setAttribute('title', 'Filter Events');
        button.setAttribute('type', 'button');

        const element = document.createElement('div');
        element.className = 'filter-event-div ol-control ol-unselectable map-control';
        element.appendChild(button);

        super({
            element: element,
            target: options.target
        })

        this.eventTypes = [];
        this.initializeEventTypeSelection();

        button.addEventListener('click', this.handleClickFilterEventsButton.bind(this), false);
    }

    initializeEventTypeSelection(){
        getEventTypes()
            .then((eventTypes)=>{
                eventTypes.types.forEach((eventType) => {
                    this.eventTypes.push(`${eventType.id}`)
                })
            })
    }

    handleClickFilterEventsButton(){
        // when the user clicks on this, we render the popup
        this.renderFilterPopUp()
    }

    handleSelectFilter(e){
        // when something is selected, see if its selected or not, if its selected add to the list
        // if its not remove from the list
        let isChecked = e.target.checked;
        let eventTypeID = e.target.getAttribute('data-event-type-id')
        if(isChecked){
            this.eventTypes.push(eventTypeID);
        }else{
            let index = this.eventTypes.indexOf(eventTypeID);
            this.eventTypes.splice(index, 1);
        }
    }

    handleClickFilterButton(){
        // refresh the map event display layer so we can get the new data
        eventDisplayLayer.getSource().clear();
        eventDisplayLayer.getSource().refresh();
    }

    getEventTypes(){
        return this.eventTypes;
    }

    renderFilterPopUp(){
        // render the popup
        const filterDiv = document.getElementById('filter-event-type-div');
        // render each event type (get it from the database)
        getEventTypes()
            .then((eventTypes) => {
                filterDiv.innerHTML = this.getEventTypeHTML(eventTypes.types);

                this.handleToggleModal();

                this.addEventListeners();

                this.checkSelectedEventTypes();
            })
    }

    checkSelectedEventTypes(){
        const eventTypeElements = document.getElementsByClassName('filter-event-checkbox');

        Array.from(eventTypeElements).forEach((element) => {
            element.checked = this.eventTypes.includes(element.getAttribute('data-event-type-id'))
        })
    }

    // generate checkboxes for every event type
    getEventTypeHTML(eventTypes){
        let returnString = '';

        eventTypes.forEach((eventType) => {
            let eventTypeString = EVENT_TYPE_CHECKBOX_TEMPLATE.replaceAll('%ID%', eventType.id)
            eventTypeString = eventTypeString.replaceAll('%NAME%', eventType.name)
            returnString += eventTypeString
        })

        return returnString
    }

    addEventListeners(){
        // add the event listeners for the filters
        const eventTypeElements = document.getElementsByClassName('filter-event-checkbox');

        Array.from(eventTypeElements).forEach((element) => {
            element.addEventListener('change', this.handleSelectFilter.bind(this))
        })
    }

    handleToggleModal(){
        const addEventModal = new bootstrap.Modal(document.getElementById('filter-event-modal'))
        addEventModal.toggle();
    }
}

export const filterEventsControl = new FilterEvents();