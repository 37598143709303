import {map} from "../Map";
import {drawLayer} from "../layers/Draw";
import {Select} from "ol/interaction";
import {Fill, Stroke, Style} from "ol/style";
import PaintBucketIcon from "/images/PaintBucketIcon.svg";
import {EventEditControl} from "./EventEditControl";

const selectStyle = new Style({
    fill: new Fill({
        color: '#eeeeee',
    }),
    stroke: new Stroke({
        color: 'rgba(255, 255, 255, 0.7)',
        width: 5,
    }),
});

class FillRegionControl extends EventEditControl {
    select;
    constructor(opt_options) {
        const options = opt_options || {}

        const icon = document.createElement('img');
        icon.src = PaintBucketIcon;

        const elementID = 'fill-region';

        const button = document.createElement('button');
        button.appendChild(icon);
        button.id = elementID;
        button.className = "disabled"
        button.setAttribute('title', 'Fill Region');
        button.setAttribute('type', 'button');
        button.disabled = true;

        const element = document.createElement('div');
        element.className = 'fill-region-div ol-control ol-unselectable map-control';
        element.appendChild(button);

        super({
            element: element,
            target: options.target
        });

        this.elementID = elementID;

        button.addEventListener('click', this.handleFillRegionEvents.bind(this), false);
        document.addEventListener('trigger_fill_region_off', this.handleFillRegionOffEvent.bind(this), false);
    }

    handleFillRegionEvents() {
        if (map.get('erase') === 'true'){
            let trigger_eraser_off_event = new Event('trigger_eraser_off');
            document.dispatchEvent(trigger_eraser_off_event);
        } else if (map.get('paint') === 'true'){
            let trigger_paint_off_event = new Event('trigger_paint_off');
            document.dispatchEvent(trigger_paint_off_event);
        } else if(map.get('fill') === 'true') {
            let trigger_fill_off_event = new Event('trigger_fill_off');
            document.dispatchEvent(trigger_fill_off_event);
        }

        if (map.get('fill-region') === 'false' || map.get('fill-region') === undefined) {
            this.addInteraction();
            map.set('fill-region', 'true');
        }
    }

    handleFillRegionOffEvent(e){
        map.set('fill-region', 'false');
        console.log('Fill Region unselected');
        this.removeInteraction();
    }

    addInteraction(){
        console.log('Fill Region selected')
        this.select = new Select({
            style: selectStyle
        })

        map.addInteraction(this.select);

        this.select.on('select', this.handleSelectFeature);
    }

    removeInteraction(){
        map.removeInteraction(this.select);
        this.select = null;
    }

    handleSelectFeature(e){
        let feature = e.target.getFeatures().getArray()[0];

        let editedFeature = drawLayer.getSource().getFeatureByUid(feature.ol_uid);
        let editedFeatureProperties = editedFeature.getProperties();
        editedFeature.getGeometry().setProperties({'tiles': editedFeatureProperties['geometry']['values_']['tiles'], 'fill': !editedFeatureProperties['geometry']['values_']['fill']}, false)

        drawLayer.changed();
    }
}

export const fillRegionControl = new FillRegionControl();
export {FillRegionControl};