import {eventDisplayLayer} from "../layers/EventDisplay";
import {map} from "../Map";
import {createPolygons} from "../events";
import {handleClickEvent} from "./ViewEvent";
import {eventLayer} from "../layers/Event";
import {ENABLE_EVENT_EDIT_MODE} from "../constants";

// function that is called when edit tile has been clicked
export let editTileHandler = (e) => {
    eventDisplayLayer.setVisible(false);
    map.removeEventListener('click', handleClickEvent);

    let featureId = document.getElementById('edit-tile').getAttribute('data-feature-id');
    let eventId = document.getElementById('edit-tile').getAttribute('data-event-id');

    document.getElementById('save-event-map-button').setAttribute('data-event-id', eventId);
    document.getElementById('save-event-map-button').setAttribute('data-feature-id', featureId);

    let feature = eventDisplayLayer.getSource().getFeatureById(featureId);
    let eventData = feature.getProperties().geometry.values_;

    // fire event to enable the tile editing mode
    let enableTileEditMode = new Event(ENABLE_EVENT_EDIT_MODE);
    document.dispatchEvent(enableTileEditMode);

    renderEventHandler(eventData);
}

let renderEventHandler = (event) => {
    eventLayer.getSource().clear();

    let tileQueue = [...event.tiles];

    renderTilesHandler(tileQueue);
}

let renderTilesHandler = (tileQueue) => {
    if(tileQueue.length === 0){
        return
    }

    let tile = tileQueue.pop();
    createPolygons(tile.z, tile.x, tile.y, '#FFFF33')

    if(tileQueue.length > 0){
        renderTilesHandler(tileQueue)
    }
}