import {clearEvents, handleEraserModeClick, handleEraserModeDrag} from "../events";
import {map} from "../Map";
import EraserIcon from "/images/EraserIcon.svg";
import {EventEditControl} from "./EventEditControl";

class ClearEventsControl extends EventEditControl {
    constructor(opt_options) {
        const options = opt_options || {}

        const icon = document.createElement('img');
        icon.src = EraserIcon;

        let elementID = 'clear-event'

        const button = document.createElement('button');
        button.appendChild(icon);
        button.id = elementID;
        button.className = "disabled"
        button.setAttribute('title', 'Clear Events');
        button.setAttribute('type', 'button');
        button.disabled = true;

        const element = document.createElement('div');
        element.className = 'clear-event-div ol-control ol-unselectable map-control';
        element.appendChild(button);

        super({
            element: element,
            target: options.target
        });

        this.elementID = elementID;

        button.addEventListener('dblclick', this.handleClearEvents.bind(this), false);
        button.addEventListener('click', this.handleEraserMode.bind(this), false);
        document.addEventListener('trigger_eraser_off', this.handleEraserOff.bind(this), false);
    }

    handleClearEvents() {
        clearEvents();
    }

    handleEraserMode(e) {
        if (map.get('fill') === 'true'){
            let trigger_fill_off_event = new Event('trigger_fill_off');
            document.dispatchEvent(trigger_fill_off_event);
        } else if (map.get('paint') === 'true'){
            let trigger_paint_off_event = new Event('trigger_paint_off');
            document.dispatchEvent(trigger_paint_off_event);
        } else if(map.get('fill-region') === 'true'){
            let trigger_fill_region_off_event = new Event('trigger_fill_region_off');
            document.dispatchEvent(trigger_fill_region_off_event);
        }

        if (map.get('erase') === 'false' || map.get('erase') === undefined){
            map.addEventListener('pointerdrag', handleEraserModeDrag);
            map.addEventListener('click', handleEraserModeClick);
            map.set('erase', 'true', true);
            console.log('Eraser selected');
        }
    }

    handleEraserOff(e) {
        map.removeEventListener('pointerdrag', handleEraserModeDrag);
        map.removeEventListener('click', handleEraserModeClick)
        map.set('erase', 'false');
        console.log('Eraser unselected');
    }
}

export const clearEventControl = new ClearEventsControl();

export {ClearEventsControl};