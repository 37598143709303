import TileLayer from "ol/layer/WebGLTile";
import ImageTile from "ol/source/ImageTile";
import {TileGrid} from "ol/tilegrid";
import {get as getProjection} from "ol/proj";
import {
    backgroundZoomRatios,
    getResolutions
} from "./Common";

const baseUrl = '/tiles'

let mapLayerTileUrlFunction = (z, x, y) => {
    let year = document.getElementById('year-input').value;

    return `${baseUrl}/${year}/${z+1}/${x}/${y}`
}

let backgroundTileGrid = new TileGrid({
    extent: getProjection('EPSG:3857').getExtent(),
    resolutions: getResolutions(1024, backgroundZoomRatios),
    tileSize: [1024, 1024]
})

export const backgroundLayer = new TileLayer({
    source: new ImageTile({
        url: mapLayerTileUrlFunction,
        tileGrid: backgroundTileGrid,
        transition: 0
    }),
})